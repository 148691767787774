import cx from 'classnames';
import { format, getWeek, isAfter, isBefore, isValid } from 'date-fns';
import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
  WeekNumberClickEventHandler,
} from 'react-day-picker';
import { usePopper } from 'react-popper';
import { useGetDateLocale } from 'src/utils/locale/dateLocale';
import { Stack } from 'src/components/ui-components/Stack';
import { Flex } from 'src/components/ui-components/Flex';
import { ButtonGhost, ButtonPrimary, ButtonSecondary } from 'src/components/ui-components/Button';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import scssSpaces from 'src/assets/styles/variables/spaces.module.scss';
import { Text } from 'src/components/ui-components/Text';
import { isMobile } from 'src/utils/isMobile';
import { parsePxValueToNumber } from '../utils/parsePxValueToNumber';
import styles from './DayRangePicker.module.scss';
import { DateInput } from './components/DateInput';
import { IconLeft } from './components/IconLeft';
import { IconRight } from './components/IconRight';
import { PreselectButton } from './components/PreselectButton';
import { WeekNumber } from './components/WeekNumber';
import { classNames } from './helpers/classNames';
import { dateIsBeforeAllowedDate } from './helpers/dateIsBeforeAllowedDate';
import { formatDate } from './helpers/formatDate';
import { isCorrectDateFormat } from './helpers/isCorrectDateFormat';
import { preselectOptionsLogic } from './helpers/preselectOptionsLogic';
import { DayRangePickerProps } from './types';

export const DayRangePicker = ({
  dateRange,
  setDateRange,
  onApplyHandler,
  buttonApplyText,
  buttonCancelText,
  inputFromLabel,
  inputToLabel,
  dateFormat = 'dd-MM-yyyy',
  dateFormatInvalidMessage,
  dateInvalidMessage,
  startDateIsOutOfRangeMessage,
  endDateIsOutOfRangeMessage,
  goToTodayLabel,
  preselectOptions,
  disableDayPicker,
  ...props
}: DayRangePickerProps) => {
  // Day picker vars
  const [resetRange, setResetRange] = useState<DateRange | undefined>(dateRange);
  const [fromValue, setFromValue] = useState<string | undefined>(
    dateRange?.from && format(dateRange.from, dateFormat),
  );

  const [toValue, setToValue] = useState<string | undefined>(
    dateRange?.to && format(dateRange.to, dateFormat),
  );
  const [inputTypeSelected, setInputTypeSelected] = useState<string>('');
  const [month, setMonth] = useState<Date | undefined>();
  const userDateLocale = useGetDateLocale();

  // For drop down
  const popperWrapper = useRef<HTMLDivElement>(null);
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);

  // Validation feedback
  const validationId = useId();
  const [validationFeedback, setValidationFeedback] = useState<string>('');

  // Popper functionality
  const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, parsePxValueToNumber(scssSpaces.spaceSize3)],
        },
      },
    ],
  });

  const openPopper = () => {
    setPopperIsOpen(true);
    setResetRange(dateRange);
    if (forceUpdate) {
      forceUpdate();
    }
  };

  useEffect(() => {
    if (dateRange?.from) {
      setFromValue(format(dateRange?.from, dateFormat));
    }
  }, [dateRange?.from, dateFormat]);

  useEffect(() => {
    if (dateRange?.to) {
      setToValue(format(dateRange?.to, dateFormat));
    }
  }, [dateRange?.to, dateFormat]);

  const handleFromChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFromValue(target.value);

    const isCorrectFormat = isCorrectDateFormat(target.value, dateFormat);

    if (isCorrectFormat) {
      const date = formatDate(target.value, dateFormat);

      if (dateIsBeforeAllowedDate(props.fromDate, props.fromMonth, date)) {
        setValidationFeedback(startDateIsOutOfRangeMessage);
        return setDateRange({ from: undefined, to: dateRange?.to });
      }

      if (!isValid(date)) {
        setValidationFeedback(dateInvalidMessage);
        return setDateRange({ from: undefined, to: dateRange?.to });
      }

      setValidationFeedback('');

      if (dateRange?.to && isAfter(date, dateRange.to)) {
        setMonth(date);
        return setDateRange({ from: date, to: date });
      }

      setMonth(date);
      return setDateRange({ from: date, to: dateRange?.to });
    }

    setValidationFeedback(dateFormatInvalidMessage);
    return setDateRange({ from: undefined, to: dateRange?.to });
  };

  const handleToChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setToValue(target.value);

    const isCorrectFormat = isCorrectDateFormat(target.value, dateFormat);

    if (isCorrectFormat) {
      const date = formatDate(target.value, dateFormat);

      if (dateIsBeforeAllowedDate(props.fromDate, props.fromMonth, date)) {
        setValidationFeedback(endDateIsOutOfRangeMessage);
        return setDateRange({ from: dateRange?.from, to: undefined });
      }

      if (!isValid(date)) {
        setValidationFeedback(dateInvalidMessage);
        return setDateRange({ from: dateRange?.from, to: undefined });
      }

      setValidationFeedback('');

      if (dateRange?.from && isBefore(date, dateRange.from)) {
        return setDateRange({ from: date, to: date });
      }

      setMonth(date);
      return setDateRange({ from: dateRange?.from, to: date });
    }

    setValidationFeedback(dateFormatInvalidMessage);
    return setDateRange({ from: dateRange?.from, to: undefined });
  };

  const handlePreselection = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setValidationFeedback('');

    const { dateFrom, period } = preselectOptionsLogic(
      currentTarget,
      dateRange?.from,
      props.locale,
      props.weekStartsOn,
    );

    setMonth(dateFrom);
    setDateRange({ from: dateFrom, to: period });
  };

  const handleInputTypeSelected = ({ currentTarget }: MouseEvent<HTMLInputElement>) => {
    setInputTypeSelected(currentTarget.name);

    if (popperIsOpen) {
      return false;
    }

    return openPopper();
  };

  const weekClickHandler: WeekNumberClickEventHandler = (weekNumber, dates) => {
    setValidationFeedback('');

    const fromDateWeekNumber =
      props.fromDate &&
      getWeek(props.fromDate, { locale: props.locale, weekStartsOn: props.weekStartsOn });

    setDateRange(() => ({
      from: fromDateWeekNumber === weekNumber ? props.fromDate : new Date(dates[0]),
      to: new Date(dates[dates.length - 1]),
    }));
  };

  const handleRangeSelect: SelectRangeEventHandler = (eventRange, day) => {
    setValidationFeedback('');

    if (inputTypeSelected === 'date-from') {
      setDateRange((prev) => {
        const toDateIsDefinedAndAfterFromDate = prev?.to && isAfter(prev.to, day);

        return {
          from: new Date(day),
          to: toDateIsDefinedAndAfterFromDate ? prev.to : new Date(day),
        };
      });

      setInputTypeSelected('');

      return false;
    }

    if (inputTypeSelected === 'date-to') {
      setDateRange((prev) => {
        const fromDateIsDefinedAndBeforeToDate = prev?.from && isBefore(day, prev.from);

        return {
          from: fromDateIsDefinedAndBeforeToDate ? new Date(day) : prev?.from,
          to: new Date(day),
        };
      });

      return false;
    }

    return setDateRange(eventRange);
  };

  const cleanUp = useCallback(() => {
    setDateRange(resetRange);
    setInputTypeSelected('');
    setPopperIsOpen(false);
    setValidationFeedback('');
  }, [resetRange, setDateRange]);

  const handleApply = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setInputTypeSelected('');
    setPopperIsOpen(false);
    setResetRange(dateRange);
    onApplyHandler();
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    cleanUp();
  };

  const handleFocus = ({ target }: FocusEvent<HTMLInputElement>) => {
    openPopper();

    if (target.name === 'date-from') {
      setMonth(dateRange?.from);
    } else {
      setMonth(dateRange?.to);
    }
  };

  // Closing the calendar on click outside
  useOnClickOutside(popperWrapper, () => {
    if (popperIsOpen) {
      cleanUp();
    }
  });

  // Closing the calendar on escape
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        cleanUp();
      }
    };

    if (popperWrapper.current) {
      popperWrapper.current.addEventListener('keydown', handleKeydown);
    }
  }, [cleanUp]);

  return (
    <div ref={popperWrapper} className={styles.form}>
      <Flex>
        <div
          className={cx(styles.dateInputs, { [styles.hasError]: validationFeedback.length > 0 })}
        >
          <DateInput
            value={fromValue}
            label={inputFromLabel}
            name="date-from"
            onClick={handleInputTypeSelected}
            onFocus={handleFocus}
            onChange={handleFromChange}
            placeholder={dateFormat}
            iconName="calendar"
            currentInputSelectedName={inputTypeSelected}
            aria-describedby={validationFeedback && validationId}
            ref={setReferenceElement}
            disabled={disableDayPicker}
          />

          <DateInput
            value={toValue}
            label={inputToLabel}
            name="date-to"
            onClick={handleInputTypeSelected}
            onFocus={handleFocus}
            onChange={handleToChange}
            placeholder={dateFormat}
            iconName="arrowRight"
            currentInputSelectedName={inputTypeSelected}
            aria-describedby={validationFeedback && validationId}
            disabled={disableDayPicker}
          />
        </div>

        {validationFeedback && (
          <Text tone="error" id={validationId}>
            {validationFeedback}
          </Text>
        )}
      </Flex>

      <div
        ref={setPopperElement}
        className={styles.dropdown}
        hidden={!popperIsOpen}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {preselectOptions && (
          <Stack verticalMargin="xxSmall" className={styles.preselects}>
            {preselectOptions?.map((item) => (
              <PreselectButton
                key={item.label}
                onClick={handlePreselection}
                value={item.periodType}
                data-period-amount={item.periodAmount}
                data-period-start-from={item.from}
              >
                {item.label}
              </PreselectButton>
            ))}
          </Stack>
        )}

        <Stack
          verticalMargin="large"
          className={cx(styles.calendar, { [styles.hasPreselect]: preselectOptions })}
        >
          <DayPicker
            data-automation-id="ReactDayRangePicker"
            selected={dateRange}
            onSelect={handleRangeSelect}
            numberOfMonths={isMobile ? 1 : 2}
            month={month}
            onMonthChange={setMonth}
            onWeekNumberClick={weekClickHandler}
            components={{ IconLeft, IconRight, WeekNumber }}
            classNames={classNames}
            locale={userDateLocale}
            {...props}
          />

          <Flex horizontalAlignment={goToTodayLabel ? 'justify' : 'right'}>
            {goToTodayLabel && (
              <ButtonGhost onClick={() => setMonth(new Date())}>{goToTodayLabel}</ButtonGhost>
            )}
            <Flex>
              <ButtonSecondary type="reset" onClick={handleCancel} size="compact">
                {buttonCancelText}
              </ButtonSecondary>
              <ButtonPrimary
                type="submit"
                size="compact"
                disabled={validationFeedback.length > 0}
                onClick={handleApply}
              >
                {buttonApplyText}
              </ButtonPrimary>
            </Flex>
          </Flex>
        </Stack>
      </div>
    </div>
  );
};
