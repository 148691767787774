// import { SelectChangeEvent } from '@mui/material';
import { TypographyTypeMap } from '@mui/material/Typography';
import { ReactNode } from 'react';
import { Badge, Stack, Typography } from 'src/components/mui-components';
// import { Select } from 'src/components/ui-components/Select';

interface WidgetHeaderProps {
  heading: string;
  widgetKey: string;
  headingAs?: TypographyTypeMap['props']['variant'];
  icon?: JSX.Element;
  count?: number;
  action?: ReactNode;
}

export const WidgetHeader = ({
  heading,
  headingAs = 'h3',
  icon,
  count = 0,
  action,
  widgetKey,
  ...props
}: WidgetHeaderProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    flexWrap="wrap"
    {...props}
  >
    <Stack direction="row" alignItems="center">
      {count > 0 && icon && (
        <Badge badgeContent={count} color="warning">
          {icon}
        </Badge>
      )}
      {!count && icon && icon}
      <Typography
        component="h2"
        fontWeight={600}
        variant={headingAs}
        data-automation-id={`WidgetHeaderHeading${widgetKey}`}
        sx={{ textAlign: 'center' }}
      >
        {heading}
      </Typography>
    </Stack>

    {action && action}
  </Stack>
);
