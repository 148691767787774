import { Close, LightbulbOutlined } from '@mui/icons-material';
import { DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from 'src/components/mui-components';
import { MultiLineTranslation } from 'src/components/utils/MultiLineTranslation';

interface ITipsButton {
  widgetKey: 'BillablePercentage' | 'ExternalPercentage' | 'RegistrationAccuracy';
}

export const WidgetTipsButton = ({ widgetKey }: ITipsButton) => {
  const { t } = useTranslation('frontpage');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        data-automation-id="WidgetTipsButton"
        startIcon={<LightbulbOutlined />}
        onClick={() => setIsOpen(true)}
      >
        {t('WidgetTipsButton')}
      </Button>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          data-automation-id={`WidgetTipsHeader${widgetKey}`}
        >
          <Typography component="h2" variant="h2">
            {t(`WidgetTipsHeader${widgetKey}`)}
          </Typography>

          <IconButton
            data-automation-id="WidgetTipsModalClose"
            onClick={close}
            title={t('WidgetTipsModalClose')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          data-automation-id={`WidgetTipsContent${widgetKey}`}
          sx={{ minWidth: '400px' }}
        >
          <DialogContentText>
            <MultiLineTranslation translatedContent={t(`WidgetTipsContent${widgetKey}`)} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
