import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  Select,
} from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Button, IconButton } from 'src/components/mui-components';
import { Delete } from '@mui/icons-material';
import { useGetProjectActions } from 'src/apis/projectsAPI';
import { useTranslation } from 'react-i18next';
import { IActions } from 'src/apis/projectsAPI/types';
import table from 'src/assets/styles/variables/colors/states/table.module.scss';
import { useExcelConfig } from './config';
import { ProjectRemoveRPDialog } from '../ProjectRemoveRPDialog';
import { ProjectActionDialog } from '../ProjectActionDialog';

interface IToolbarProps {
  selectedRowProject: any[];
  columns: GridColDef[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
  applyProjectsUpdate: (uri?: string, validDate?: Date, action?: any) => void;
}

export const Toolbar = ({
  selectedRowProject,
  columns,
  setColumnsButtonEl,
  filterSection,
  applyProjectsUpdate,
}: IToolbarProps) => {
  const { t } = useTranslation('searchProjects');
  const excelConfig = useExcelConfig();

  const [status, setStatus] = useState('');
  const [state, setState] = useState('');

  // Project remove resource planner dialog
  const [showRemoveResourcePlannerDialog, setShowRemoveResourcePlannerDialog] = useState(false);

  const [selectedAction, setSelectedAction] = useState<IActions>({
    name: '',
    uri: '',
  });

  // Project status or stage update Dialog
  const [showActionUpdate, setShowActionUpdate] = useState(false);

  const { actions } = useGetProjectActions();

  const statusActions = actions.find((item) => item.name === 'ChangeStatusTo')?.actions || [];
  const stageActions = actions.find((item) => item.name === 'ChangeStageTo')?.actions || [];

  useEffect(() => {
    setStatus('');
    setState('');
  }, [selectedRowProject]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedActionBody = statusActions.find((action) => action.name === event.target.value);
    if (selectedActionBody) {
      setStatus(event.target.value);
      setShowActionUpdate(true);
      setSelectedAction({
        name: selectedActionBody.name,
        uri: 'status',
        body: selectedActionBody?.body,
      });
    }
  };

  const handleStageChange = (event: SelectChangeEvent) => {
    const selectedActionBody = stageActions.find((action) => action.name === event.target.value);
    if (selectedActionBody) {
      setState(event.target.value);
      setShowActionUpdate(true);
      setSelectedAction({
        name: selectedActionBody.name,
        uri: 'stage',
        body: selectedActionBody?.body,
      });
    }
  };

  return (
    <>
      <GridToolbarContainer sx={{ p: 0 }}>
        <Box display="flex" width="100%">
          <Stack
            direction="row"
            justifyContent={selectedRowProject.length > 0 ? 'flex-start' : 'flex-end'}
            width="100%"
            sx={{ pb: 1 }}
          >
            {selectedRowProject.length > 0 ? (
              <Stack
                display="flex"
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                width="100%"
                sx={{ backgroundColor: table.colorActionBar, p: 1, flexWrap: 'wrap' }}
              >
                <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {selectedRowProject.length} {t('SelectedText')}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
                  {statusActions && (
                    <FormControl sx={{ minWidth: 200 }} size="small">
                      <InputLabel id="SelectStatus-label">{t('ChangeStatusToText')}</InputLabel>
                      <Select
                        labelId="SelectStatus-label"
                        name="status"
                        value={status}
                        label={t('ChangeStatusToText')}
                        onChange={handleChange}
                        sx={{ backgroundColor: 'white' }}
                        data-automation-id="ProjectStatusSelect"
                      >
                        {statusActions.map((action) => (
                          <MenuItem key={action.name} value={action.name}>
                            {t(`Status${action.name}Text`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="SelectStage">{t('ChangeStageToText')}</InputLabel>
                    <Select
                      labelId="SelectStage"
                      value={state}
                      label={t('ChangeStageToText')}
                      onChange={handleStageChange}
                      sx={{ backgroundColor: 'white' }}
                      data-automation-id="ProjectStageSelect"
                    >
                      {stageActions.map((action) => (
                        <MenuItem key={action.name} value={action.name}>
                          {action.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setShowRemoveResourcePlannerDialog(true)}
                  >
                    {t('DropdownDisableResourcePlannerText')}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      applyProjectsUpdate('resource-planner', undefined, {
                        isAddingToResourcePlanner: true,
                      })
                    }
                  >
                    {t('DropdownEnableResourcePlannerText')}
                  </Button>
                  <IconButton
                    title={t('DeleteProjectText')}
                    data-automation-id="ProjectDeleteTriggerButton"
                    onClick={(event) => {
                      event.preventDefault();
                      applyProjectsUpdate('delete');
                    }}
                    sx={{ display: 'flex' }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            ) : (
              <>
                {filterSection}
                <GridToolbarColumnsButton
                  ref={setColumnsButtonEl}
                  slotProps={{
                    button: {
                      sx: {
                        minWidth: 10,
                        pl: 2,
                      },
                    },
                  }}
                />
                <GridToolbarExportContainer
                  slotProps={{
                    button: {
                      sx: {
                        minWidth: 10,
                        pl: 2,
                      },
                    },
                  }}
                >
                  <GridCsvExportMenuItem />
                  <GridExcelExportMenuItem columns={columns} config={excelConfig} />
                </GridToolbarExportContainer>
              </>
            )}
          </Stack>
        </Box>
      </GridToolbarContainer>
      {/* Project remove from resource planner Dialog */}
      <ProjectRemoveRPDialog
        showRemoveResourcePlannerDialog={showRemoveResourcePlannerDialog}
        setShowRemoveResourcePlannerDialog={setShowRemoveResourcePlannerDialog}
        applyProjectsUpdate={applyProjectsUpdate}
      />

      {/* Project status or stage Dialog when click Apply */}
      <ProjectActionDialog
        showActionUpdate={showActionUpdate}
        setShowActionUpdate={setShowActionUpdate}
        applyProjectsUpdate={applyProjectsUpdate}
        selectedAction={selectedAction}
      />
    </>
  );
};
