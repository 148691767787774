import { toCamel } from 'src/utils/object';

const mainResult = {
  Entities: [
    {
      Properties: {
        PageId: '72b84567-5a4b-e311-9d8f-9c1f9ef22d51',
        Name: 'Front page',
        Type: 'Main',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Home/Index',
        PageIdentifier: 'Home',
        PageType: 'Normal',
        PageVersion: 'DesignSix',
      },
    },
    {
      Properties: {
        Name: 'Register',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'f0dec4f8-8d4a-4b7f-8021-cedd68f1dbbb',
            Name: 'Time',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/TimeTracking/Index',
            PageIdentifier: 'TimeTrackingBase',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '7bb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Travels',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/Timesheet/TravelExpense',
            PageIdentifier: 'TravelExpenses',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '26539faa-9951-4170-b761-3cdb07105845',
            Name: 'Submit/print personal expenses',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Reports/Personal/ExpenseReportSubmitPrint',
            PageIdentifier: 'ExpenseReportSubmitPrint',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '69c75110-1e71-e311-8bf6-d28551383fc4',
            Name: 'Time tracking apps',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/Timesheet/TrackerInstall',
            PageIdentifier: 'TimeLogTrackerInstall',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: 'c4b84567-5a4b-e311-9d8f-9c1f9ef22d51',
        Name: 'Reports',
        Type: 'Main',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Reports/Report/Index',
        PageIdentifier: 'Reports',
        PageType: 'LinkToPage',
        PageVersion: 'DesignSix',
      },
    },
    {
      Properties: {
        Name: 'Projects',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'a0570ad2-c4bd-4815-bdb3-bdd421dd4d21',
            Name: 'Search projects',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Search/SearchProjects',
            PageIdentifier: 'SearchProject',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'c3e04a88-0a00-487f-9e59-9d101a96462f',
            Name: 'Search projects (New)',
            Type: 'Main',
            IsParent: false,
            PageUrl: '/projects/search-projects',
            PageIdentifier: 'DesignSevenSearchProject',
            PageType: 'Normal',
            PageVersion: 'DesignSeven',
          },
          {
            PageId: 'c210b1c5-f3df-44d8-b17c-43c10a504c0d',
            Name: 'Resource planner',
            Type: 'Main',
            IsParent: false,
            PageUrl: '/projects/resource-planner',
            PageIdentifier: 'NewResourcePlanner',
            PageType: 'Normal',
            PageVersion: 'DesignSeven',
          },
          {
            // TODO: Change to correct pageId
            PageId: 'b7e6e6d2-d2bb-4268-a68e-20aee5823703',
            Name: 'Move hours',
            Type: 'Main',
            IsParent: false,
            PageUrl: '/projects/move-hours',
            PageIdentifier: 'DesignSevenMoveHours',
            PageType: 'Normal',
            PageVersion: 'DesignSeven',
          },
          {
            PageId: '83179590-6479-4f44-811e-3fbd26ae2563',
            Name: 'Manage hourly rates',
            Type: 'Main',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/ProjectManagement/Adjustment/ChangeHourlyRatesOnAllocations',
            PageIdentifier: 'ChangeHourlyRatesOnAllocations',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '2a8a41a0-2b55-4388-a0fb-c98259febc37',
            Name: 'Move allocations',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Adjustment/MoveAllocations',
            PageIdentifier: 'MoveAllocations',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'eaa42259-93a5-40be-b142-f99de32be7b4',
            Name: 'Adjust project payments',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Adjustment/AdjustProjectPayments',
            PageIdentifier: 'AdjustProjectPayments',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '029d95e5-30bd-4df0-a4f8-e21bb3ef946a',
            Name: 'Index hourly rates',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/IndexationOfHourlyRate/Index',
            PageIdentifier: 'IndexationOfHourlyRateBase',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Employees',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: '466d91d9-be4f-45ae-8653-af733a491c47',
            Name: 'Search employees',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Employee/EmployeeOverview/Index',
            PageIdentifier: 'EmployeeOverviewBase',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'abb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Resource planner',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/ResourcePlanner',
            PageIdentifier: 'ResourcePlanner',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'b7b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'CV overview',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/CVOverview',
            PageIdentifier: 'CVOverview',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '3c2ea1f0-1871-4316-80cd-dd7118abce13',
            Name: 'Salary management',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Employee/Salary/SalaryManagementOverview',
            PageIdentifier: 'SalaryManagementOverview',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'abb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Resource planner',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/ResourcePlanner',
            PageIdentifier: 'ResourcePlannerOld',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Invoices',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'a0b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Invoice drafts',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/SearchInvoice',
            PageIdentifier: 'SearchInvoice',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '35ea40f3-3e65-4c59-aa46-6c4891ce10f8',
            Name: 'Invoice archive',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/SearchInvoiceArchive',
            PageIdentifier: 'InvoiceArchive',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'cad065b6-336b-44c4-8bbf-d8a307836d28',
            Name: 'Debtor list - invoices',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/DebtorListInvoice',
            PageIdentifier: 'NewDebtorListInvoice',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '9eb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Debtor list - vouchers',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/DebtorListVouchers',
            PageIdentifier: 'DebtorListVouchers',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'a2b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Transfer invoices',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/TransferInvoices',
            PageIdentifier: 'TransferInvoices',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Customers',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'c6b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'My overview',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/CRM/Home/Index',
            PageIdentifier: 'CRMHomeStart',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '84b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Search customers',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/CRM/Customer/Index',
            PageIdentifier: 'CRMCustomer',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '86b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Search contact',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/CRM/Contact/Index',
            PageIdentifier: 'CRMContact',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
  ],
  Links: [{ Href: 'http://localhost/tlp/api/v2/menus/main', Rel: 'self' }],
};

export const mainResultCamelCase = toCamel(mainResult);

export default mainResult;
